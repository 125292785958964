const settings = {
  dev: {
    baseURL: 'http://localhost:9000',
    avatarURLPrefix: 'https://avatar.nafsiaclinic.com',
  },
  prod: {
    baseURL: 'https://backend-dashboard.nafsiaclinic.com',
    avatarURLPrefix: 'https://avatar.nafsiaclinic.com',
  },
}

const getCurrentSettings = () => {
  // if (__DEV__) return settings.dev;
  //if (Constants.manifest.releaseChannel === 'staging') return settings.staging;
  return settings.prod
}

export default getCurrentSettings()
