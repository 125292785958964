import client from './client'

export const login = (loginInfos) => {
  return client.post('/auth/loginEmployee', loginInfos)
}
export const registerEmployee = (userInfo) => {
  return client.post('/auth/registerEmployee', userInfo)
}
export const me = () => {
  return client.get('/auth/meEmployee')
}
